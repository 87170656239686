import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'

const Main = styled.main`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    flex-direction: column;
`
const Ul = styled.ul`
    margin: 0 1rem;
    padding: 0;
    list-style: none;
`

const Contacto = ({ location }) => (
    <Layout seourl={location.pathname} seotitle="Convenience Store Mini Market Tienda de Conveniencia">
        <Main>
            <h2>Contactanos</h2>
            <Ul>
                <li>
                    <b>Dirección:</b> De la Rotonda del Multiplaza 1.2KM al
                    Noroeste Frente al Colegio Blue Valley. San José,
                    Guachipelin -{' '}
                    <a href="https://goo.gl/maps/ntPbPg3uZRLKvqkj8">Mapa</a>
                </li>
                <li>
                    <b>Teléfono:</b> <a href="tel:50660133397">60133397</a>
                </li>
                <li>
                    <b>Correo:</b>{' '}
                    <a href="mailto:corporate@pulpecr.com">
                        corporate@pulpecr.com
                    </a>
                </li>
            </Ul>
        </Main>
    </Layout>
)

export default Contacto
